<template>
  <v-container>
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="10"
        sm="10"
        md="8"
      >
        <h1>Kit</h1>
        <v-form lazy-validation>
          <v-text-field
            v-model="kit.nome"
            label="Nome"
            name="nome"
            type="text"
            :rules="nameRules"
          />

          <v-text-field
            v-model="kit.grupo"
            label="Grupo"
            name="grupo"
            type="text"
            :rules="nameRules"
          />

          <v-text-field
            v-model="kit.catalogoProduto"
            label="Catálogo Produto"
            name="catalogoProduto"
            type="text"
          />

          <v-text-field
            v-model="kit.referencia"
            label="Referência"
            name="referencia"
            type="text"
            :rules="nameRules"
          />

          <tiptap-vuetify
            v-model="kit.descricao"
            label="Descrição"
            name="descricao"
            :extensions="extensions"
          />

          <v-file-input
            v-model="foto"
            accept="image/*"
            chips
            label="Imagem principal"
          />

          <v-autocomplete
            v-model="selectedCategorias"
            label="Categoria"
            :items="categorias"
            item-text="nome"
            item-value="categoriaId"
            :rules="nameRules"
            chips
            multiple
          />
          <v-switch
            v-model="kit.ativo"
            :label="kit.ativo ? 'Kit está ATIVO' : 'Kit está INATIVO'"
          />
          <v-switch
            v-model="kit.oportunidade"
            label="Oportunidade?"
          />
        </v-form>
        <v-alert
          v-if="!kit.agrupamento.length"
          border="right"
          colored-border
          type="error"
          elevation="2"
          class="mt-4"
        >
          Esse kit ainda não possui produtos.
        </v-alert>
        <template v-else>
          <h3 class="mt-4">
            Produtos do kit
          </h3>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>
                    Referência
                  </th>
                  <th>
                    Nome
                  </th>
                  <th>
                    Preço bruto (R$)
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in kit.agrupamento"
                  :key="item.id"
                >
                  <td>{{ item.referencia }}</td>
                  <td>{{ item.nome }}</td>
                  <td>
                    <input
                      v-model="item.valorBruto"
                      type="number"
                      class="form-control"
                      step="0.01"
                    >
                  </td>
                  <td>
                    <v-btn
                      color="red accent-4"
                      class="ma-2 white--text"
                      small
                      @click="rmProduto(item)"
                    >
                      Remover
                      <v-icon
                        right
                        dark
                      >
                        mdi-minus
                      </v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </template>
        <v-btn
          block
          color="primary"
          class="mt-4"
          @click="submit"
        >
          Salvar
        </v-btn>
      </v-col>
    </v-row>
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="10"
        sm="10"
        md="8"
      >
        <h3>Buscar e adicionar produtos</h3>
        <v-card class="mb-4">
          <v-col cols="12">
            <v-form @submit.prevent="load">
              <v-row class="p-2">
                <v-col
                  cols="6"
                  sm="6"
                  md="6"
                >
                  <v-text-field
                    v-model="filter.Nome"
                    label="Nome"
                    required
                  />
                </v-col>
                <v-col
                  cols="6"
                  sm="6"
                  md="6"
                >
                  <v-text-field
                    v-model="filter.Referencia"
                    label="Referência"
                    required
                  />
                </v-col>
                <v-col
                  cols="6"
                  sm="6"
                  md="6"
                >
                  <v-switch
                    v-model="filter.somentePromocao"
                    label="Somente promoção?"
                  />
                </v-col>
                <v-col
                  cols="6"
                  sm="6"
                  md="6"
                >
                  <v-switch
                    v-model="filter.oportunidade"
                    label="Somente oportunidades?"
                  />
                </v-col>
                <v-col
                  cols="6"
                  sm="6"
                  md="3"
                  lg="3"
                >
                  <v-btn
                    block
                    color="primary"
                    style="margin-top: 16px;"
                    @click="load"
                  >
                    Buscar
                  </v-btn>
                </v-col>
                <v-col
                  cols="6"
                  sm="6"
                  md="3"
                  lg="3"
                >
                  <v-btn
                    block
                    color="primary"
                    style="margin-top: 16px;"
                    @click="cleanFilter"
                  >
                    Limpar
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-card>
        <v-data-table
          :headers="headers"
          :items="produtos"
          :options.sync="options"
          :server-items-length="total"
          class="elevation-1"
          :footer-props="footer_props"
        >
          <template v-slot:body="{ items }">
            <tbody>
              <tr
                v-for="item in items"
                :key="item.id"
              >
                <td
                  class="text-decoration-underline"
                  @click.prevent="openProduto(item.id)"
                >
                  {{ item.id }}
                </td>
                <td>{{ item.nome }}</td>
                <td>{{ item.referencia }}</td>
                <td>
                  <v-btn
                    color="blue-grey"
                    class="ma-2 white--text"
                    small
                    :disabled="exists(item)"
                    @click="addProduto(item)"
                  >
                    Adicionar
                    <v-icon
                      right
                      dark
                    >
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify'

  export default {
    components: {
      TiptapVuetify,
    },
    data: () => ({
      options: {
        sortBy: [],
        sortDesc: [],
      },
      filter: {
        PerPage: 5,
        Page: 1,
        SortAscending: true,
        IncluirEstoqueZerado: true,
        ativo: true,
      },
      kit: {
        agrupamento: [],
        ativo: true,
      },
      selectedCategorias: [],
      categorias: [],
      produtos: [],
      headers: [
        { text: 'ProdutoID', sortable: false },
        { text: 'Nome', sortable: false },
        { text: 'Referência', sortable: false },
        { text: 'Ações', sortable: false },
      ],
      total: 0,
      footer_props: {
        'items-per-page-options': [5],
      },
      foto: null,
      nameRules: [
        v => !!v || 'Este campo é obrigatório',
      ],
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [Heading, {
          options: {
            levels: [1, 2, 3],
          },
        }],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak,
      ],
    }),
    watch: {
      options: {
        handler () {
          this.load()
        },
        deep: true,
      },
    },
    mounted () {
      this.$http.get('/produto/categoria')
        .then(resp => {
          this.categorias = resp.data.rows
        })
    },
    methods: {
      submit () {
        this.kit.produtosCategoria = this.selectedCategorias
        const config = {
          headers: {
            'content-type': 'multipart/form-data',
          },
        }
        const formData = new FormData()
        this.kit.produtosCategoria = this.kit.produtosCategoria.map(x => {
          return {
            CategoriaId: x,
          }
        })

        if (this.foto) {
          formData.append('foto', this.foto)
        }

        formData.append('produto', JSON.stringify(this.kit))

        this.$http
          .post('/produtos', formData, config)
          .then(() => {
            this.$toast.success('Operação realizada com sucesso')
            this.$router.push('/pages/produtos')
            this.load()
          })
          .catch(() => {
            this.$toast.error('Falha ao realizar operação')
          })
      },
      exists (item) {
        return this.kit.agrupamento.find(el => el.id === item.id) != null
      },
      addProduto (item) {
        item.destinoId = item.id
        item.valorBruto = 0
        this.kit.agrupamento.push(item)
      },
      rmProduto (item) {
        this.kit.agrupamento = this.kit.agrupamento.filter(el => el.id !== item.id)
      },
      openProduto (itemid) {
        const routeData = this.$router.resolve({ path: `/pages/produtos/${itemid}` })
        window.open(routeData.href, '_blank')
      },
      load () {
        this.filter.PerPage = this.options.itemsPerPage
        this.filter.Page = this.options.page
        const query = JSON.stringify(this.filter)
        this.$http.get('/produtos', { params: { query } })
          .then(({ data }) => {
            this.produtos = data.rows
            this.total = data.total
          })
          .catch(() => {
            this.$toast.error('Falha ao realizar operação')
          })
      },
      cleanFilter () {
        this.filter.Nome = null
        this.filter.Referencia = null
        this.filter.PerPage = 5
        this.filter.Page = 1
        this.filter.somentePromocao = false
        this.filter.oportunidade = false
        this.load()
      },
    },
  }
</script>

<style>
.form-control {
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.text-decoration-underline {
  cursor: pointer;
}
</style>
